import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from 'effector-react';
import { effector } from './global/effector/index';
import { effector as cartEffector } from './cart/effector/index';
import { effector as productEffector } from './productsList/effector/index';
import { Link, useLocation } from 'react-router-dom';
import logoImg from '../images/logo.svg';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { MenuButtons } from './global/components/menuButtons/index';
import { RouterContainer } from './routerContainer/index';
import { Promo } from './global/components/promo/index';

const useStyles = makeStyles({
	marginAuto: {
		margin: '0px auto',
		maxWidth: '1500px',
	},
});

const theme = createMuiTheme({
	typography: {
		fontFamily: ['Mont-regular'].join(','),
	},
});

export const App = () => {
	const classes = useStyles();

	const { $screen, $categoryList, $promo } = useStore(effector.stores);

	const { $productsList } = useStore(productEffector.stores);

	const [imgArr, setImgArr] = useState([]);

	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		productEffector.effects.getProductList();

		if ($categoryList.data.length === 0) {
			effector.effects.getCategoryList();
		}
		if (localStorage.getItem('cart')) {
			cartEffector.events.initialCart(localStorage.getItem('cart'));
		}
		if (localStorage.getItem('user')) {
			cartEffector.events.initialUser(localStorage.getItem('user'));
		}
		if (localStorage.getItem('address')) {
			cartEffector.events.initialAddress(localStorage.getItem('address'));
		}
		if (sessionStorage.getItem('promo') === null) {
			effector.effects.getPromo();
		}

		window.innerWidth < 1000
			? effector.events.checkScreenSize(false)
			: effector.events.checkScreenSize(true);
	}, []);

	useEffect(() => {
		const arr = [];

		for (let item of $productsList.data.products) {
			arr.push(item.previewImg.url);
		}

		setImgArr(arr);
	}, [$productsList.data.products]);

	const date = new Date();

	return (
		<div
			className={clsx({ [classes.marginAuto]: $screen.size })}
			style={{
				position: 'relative',
				padding: '0 20px 20px',
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				minHeight: '100vh',
			}}>
			<ThemeProvider theme={theme}>
				{$promo.status ? <Promo /> : false}

				<div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '20px 0',
							position: 'sticky',
							zIndex: '20',
							top: 0,
							backgroundColor: '#fff',
						}}>
						<Link to='/'>
							<div
								style={{
									fontFamily: 'Mont-bold',
									fontSize: $screen.size ? '35px' : '20px',
									display: 'flex',
									alignItems: 'center',
									width: $screen.size ? '' : '200px',
								}}>
								<img src={logoImg} alt='logo' style={{ height: '50px', marginRight: '10px' }} />
								BALTIC HOME
							</div>
						</Link>

						<MenuButtons />
					</div>

					<section style={{ position: 'relative' }}>
						<RouterContainer />
					</section>
				</div>
			</ThemeProvider>

			<div style={{ position: 'absolute', zIndex: '-10', opacity: 0 }}>
				{imgArr.map((item, index) => {
					return (
						<img key={`PRE${index}`} src={item} alt='pre' style={{ width: '0px', height: '0px' }} />
					);
				})}
			</div>

			<footer style={{ marginTop: '20px' }}>
				<Divider />
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						height: '50px',
						marginTop: '20px',
					}}>
					<a href='http://arasolix.com/' rel='noreferrer' target='_blank'>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<span style={{ fontSize: '30px', fontFamily: 'Yarin', marginRight: '10px' }}>
								ARASOLIX
							</span>
							<span>Разработка от ARASOLIX</span>
						</div>
					</a>
					<span>OOO "AZIRUS" {date.getFullYear()}</span>
				</div>
			</footer>
		</div>
	);
};
