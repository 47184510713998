import React from 'react';
import { useStore } from 'effector-react';
import { effector } from '../global/effector/index';

import magresImg from '../../images/magres_logo.png';

export const Magres = () => {
	const { $screen } = useStore(effector.stores);

	return (
		<div>
			<div style={{ position: 'relative' }}>
				<img
					style={{ width: '100%' }}
					src='https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/static-img%2Fmagres.jpg?alt=media&token=56b143e8-3115-450b-a50a-daa0d14db87c'
					alt='ideallux'
				/>
				<div
					style={{
						display: $screen.size ? 'flex' : 'none',
						justifyContent: 'flex-start',
						alignItems: 'flex-end',
						fontFamily: 'Mont-bold',
						fontSize: '40px',
						position: 'absolute',
						height: '100%',
						width: '100%',
						bottom: '30px',
						left: '30px',
						color: '#fff',
					}}>
					MAGRES BALDAI
				</div>
			</div>
			<div style={{ margin: $screen.size ? '50px' : '20px' }}>
				<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
					<img src={magresImg} alt='magres-logo' />
				</div>
				<div style={{ fontSize: '20px' }}>
					<p>
						Magrės Baldai – семейное предприятие литовского капитала, находящееся в небольшом
						городке Риеше в Вильнюсском районе. Предприятие успешно развивает свою деятельность с
						1996 года. Название предприятия когда-то было вдохновлено дочерями основателя - Мартой и
						Гретой.
					</p>
					<p>
						Сегодня команда Magrės baldai, насчитывающая более двухсот сотрудников, обеспечивает
						процесс производства литовской мебели премиум-класса, классические современные
						дизайнерские решения и квалифицированную помощь семьям, которые выбирают мебель в
						салонах Magrės baldai или онлайн в большинстве городов страны.
					</p>
					<p>
						Уникальность Magrės Baldai заключается в производстве семейной мягкой мебели,
						адаптированной к ритму современной семейной жизни, за что мы ежегодно награждаемся
						титулом «Литовского продукта года».
					</p>
					<p>
						Можно с уверенностью сказать, что Magrės Baldai вообще не существовал бы без сильных,
						современных семей, которые любят и дорожат своим домом. Но также, как мы не можем
						представить себе крепкую семью без прочной, современной и долговечной мягкой мебели,
						украшающей дом! Это величайший смысл и награда нашей работы:
					</p>
					<p>
						ИЗГОТОВИТЬ И ПРОДАТЬ КАЖДУЮ МЕБЕЛЬ ТАК, КАК БУДТО ОТ НЕЕ ЗАВИСЕЛО БЫ СЧАСТЬЕ ДАЛЬНЕЙШЕЙ
						СЕМЕЙНОЙ ЖИЗНИ НАШИХ КЛИЕНТОВ
					</p>
				</div>
			</div>
		</div>
	);
};
