import React, { useEffect } from 'react';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export const Counter = () => {
	const { $product, $selected } = useStore(effector.stores);

	useEffect(() => {
		effector.events.setQty(1);
	}, [$selected.index]);

	const minus = () => {
		if ($selected.qty > 0) {
			effector.events.setQty($selected.qty - 1);
		}
	};

	const plus = () => {
		if (Number($product.data.price[$selected.index].qty) >= $selected.qty) {
			effector.events.setQty($selected.qty + 1);
		}
	};

	return (
		<div style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
			<Button onClick={minus} disabled={$selected.qty === 1}>
				<RemoveIcon />
			</Button>
			<div style={{ width: '30px', textAlign: 'center' }}>{$selected.qty}</div>
			<Button
				onClick={plus}
				disabled={Number($product.data.price[$selected.index].qty) === $selected.qty}>
				<AddIcon />
			</Button>
		</div>
	);
};
