import React, { useState } from 'react';
import { effector as cartEffector } from '../../../cart/effector/index';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';
import { useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { Notification } from '../../../../presets/notification/index';

export const AddToCart = () => {
	const { $cart } = useStore(cartEffector.stores);

	const { $product, $selected } = useStore(effector.stores);

	const [error, setError] = useState(false);

	const [complete, setComplete] = useState(false);

	const urlParams = useParams();

	const add = () => {
		const copyOfProduct = JSON.parse(JSON.stringify($product.data));

		const product = {
			...copyOfProduct,
			price: {
				...copyOfProduct.price[$selected.index],
				qty: copyOfProduct.price[$selected.index].qty >= $selected.qty ? $selected.qty : 0,
			},
		};

		const findProduct = () => {
			return $cart.data.find(
				(item) =>
					item.id === Number(urlParams.id) &&
					item.price.materialColorName === $product.data.price[$selected.index].materialColorName
			);
		};

		if ($cart.data.length === 0 || (findProduct() === undefined) === true) {
			if (product.price.qty > 0) {
				setComplete(true);
				return cartEffector.events.addProductToCart(product);
			} else {
				return setError(true);
			}
		} else {
			const findedProduct = findProduct();
			if (
				Number(findedProduct.price.qty) + $selected.qty <=
				Number($product.data.price[$selected.index].qty)
			) {
				const findedProductIndex = $cart.data.findIndex(
					(item) =>
						item.id === Number(urlParams.id) &&
						item.price.materialColorName === $product.data.price[$selected.index].materialColorName
				);
				setComplete(true);
				return cartEffector.events.editQty({ index: findedProductIndex, qty: $selected.qty });
			} else {
				return setError(true);
			}
		}
	};

	return (
		<div>
			<Button color='primary' variant='contained' onClick={add}>
				Добавить в корзину
			</Button>
			<Notification
				text='Товар добавлен в коризну'
				onExited={() => setComplete(false)}
				type='success'
				state={complete}
			/>
			<Notification
				text='Больше товара нет'
				onExited={() => setError(false)}
				type='error'
				state={error}
			/>
		</div>
	);
};
