import React from 'react';
import { useStore } from 'effector-react';
import { effector } from '../../effector/index';
import { effector as globalEffector } from '../../../global/effector/index';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
	selected: {
		height: '70px',
		width: '70px',
		borderRadius: '5px',
		border: '3px solid #afafaf',
		marginRight: '20px',
	},
	notSelected: {
		height: '70px',
		width: '70px',
		borderRadius: '5px',
		border: '3px solid transparent',
		marginRight: '20px',

		'&:hover': {
			border: '3px solid #afafaf',
			cursor: 'pointer',
		},
	},
});

export const ColorSelector = () => {
	const classes = useStyles();

	const { $screen } = useStore(globalEffector.stores);

	const { $product, $selected } = useStore(effector.stores);

	const formatter = new Intl.NumberFormat({
		style: 'currency',
		currency: 'UZS',
		minimumFractionDigits: 0,
	});

	const creditContol = () => {
		if ($product.data.price[$selected.index].credit) {
			return (
				<>
					<div>В кредит без процентов:</div>
					<div
						style={{
							fontFamily: 'Mont-thin',
							margin: '10px 0',
							fontSize: $screen.size ? '40px' : '30px',
						}}>
						{formatter.format($product.data.price[$selected.index].credit)} СУМ
					</div>
				</>
			);
		}

		return false;
	};

	return (
		<>
			<div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '20px' }}>
				{$product.data.price.map((item, index) => {
					return (
						<Tooltip key={`${index}PRICE`} title={`${item.materialName} ${item.materialColorName}`}>
							<div onClick={() => effector.events.setSelected(index)}>
								<img
									src={item.img.url}
									alt={item.img.name}
									className={clsx({
										[classes.selected]: $selected.index === index,
										[classes.notSelected]: $selected.index !== index,
									})}
								/>
							</div>
						</Tooltip>
					);
				})}
			</div>
			<div style={{ marginBottom: '20px' }}>
				<div>Материал и цвет:</div>
				<div
					style={{
						fontFamily: 'Mont-thin',
						margin: '10px 0',
						fontSize: $screen.size ? '40px' : '30px',
					}}>
					{$product.data.price[$selected.index].materialName}{' '}
					{$product.data.price[$selected.index].materialColorName}
				</div>
				<div>Стоимость:</div>
				<div
					style={{
						fontFamily: 'Mont-thin',
						margin: '10px 0',
						fontSize: $screen.size ? '40px' : '30px',
					}}>
					{formatter.format($product.data.price[$selected.index].cost)} СУМ
				</div>
				{creditContol()}
			</div>
		</>
	);
};
