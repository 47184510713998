import { createStore } from 'effector';
import * as effects from './effects';

export const $landing = createStore({
	loading: false,
	error: null,
	data: {
		header: { img: { url: '', name: '' }, link: '', title: '' },
		listTitle: '',
		list: [
			{ img: { url: '', name: '' }, link: '', title: '' },
			{ img: { url: '', name: '' }, link: '', title: '' },
			{ img: { url: '', name: '' }, link: '', title: '' },
		],
		big: { img: { url: '', name: '' }, link: '', title: '' },
		half1: { img: { url: '', name: '' }, link: '', title: '' },
		half2: { img: { url: '', name: '' }, link: '', title: '' },
	},
})
	.on(effects.getLandingInfo.pending, (state, pending) => {
		return {
			...state,
			loading: pending,
		};
	})
	.on(effects.getLandingInfo.done, (state, response) => {
		return {
			...state,
			data: response.result,
			error: null,
		};
	})
	.on(effects.getLandingInfo.fail, (state, response) => {
		return {
			...state,
			error: response.error,
		};
	});
