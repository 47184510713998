import React from 'react';
import { effector as globalEffector } from '../../../global/effector/index';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';

export const MobileHeader = () => {
	const { $categoryList } = useStore(globalEffector.stores);

	const urlParams = useParams();

	return (
		<div
			style={{
				padding: '20px',
				textAlign: 'center',
				fontFamily: 'Mont-thin',
				fontSize: '30px',
			}}>
			{$categoryList.data.map((item) => {
				if (item.id === Number(urlParams.category)) {
					return item.name;
				}
        else{
          return false
        }
			})}
		</div>
	);
};
