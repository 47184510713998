import { combine } from 'effector';
import * as effects from './effects';
import * as stores from './stores';
import * as events from './events';

export const effector = {
	stores: combine(stores),
	effects,
	events,
};
