import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';
import { useHistory, useParams } from 'react-router-dom';

export const PaginationList = () => {
	const { $productsList } = useStore(effector.stores);

	const urlParams = useParams();

	const history = useHistory();

	return (
		<div style={{ padding: '50px 0 20px', display: 'flex', justifyContent: 'center' }}>
			<Pagination
				page={$productsList.data.currentPage}
				onChange={(e, value) =>
					history.push(
						`/products/page=${value}/category=${urlParams.category}/${
							urlParams.search ? `search=${urlParams.search}` : ''
						}`
					)
				}
				count={$productsList.data.allPages}
				shape='rounded'
			/>
		</div>
	);
};
