import { createStore } from 'effector';
import * as effects from './effects';
import * as events from './events';

export const $productsList = createStore({
	loading: false,
	error: null,
	data: { allPages: 1, currentPage: 1, products: [], totalProducts: 1, currentList: [] },
})
	.on(effects.getProductList.pending, (state, pending) => {
		return {
			...state,
			loading: pending,
		};
	})
	.on(effects.getProductList.done, (state, response) => {
		return {
			...state,
			data: response.result,
			error: null,
		};
	})
	.on(effects.getProductList.fail, (state, response) => {
		return {
			...state,
			error: response.error,
		};
	})
	.on(events.currentListParams, (state, params) => {
		return {
			...state,
			data: {
				...state.data,
				...params,
			},
		};
	});
