import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { effector as globalEffector } from '../global/effector/index';
import { effector } from './effector/index';
import { useStore } from 'effector-react';
import { makeStyles } from '@material-ui/styles';

import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

import { CategoryList } from './components/categoryList/index';
import { MobileHeader } from './components/mobileHeader/index';
import { PaginationList } from './components/paginationList/index';
import { ItemList } from './components/itemList/index';

const useStyles = makeStyles({
	skeleton: {
		borderRadius: '5px',
		margin: '20px',
	},
});

export const ProductsList = () => {
	const classes = useStyles();

	const urlParams = useParams();

	const { $screen } = useStore(globalEffector.stores);

	const { $productsList } = useStore(effector.stores);

	const [imgArr, setImgArr] = useState([]);

	const changeCurrentList = (origianl, params) => {
		new Promise((resolve) => {
			const arr = [];

			origianl.forEach((item, index) => {
				if (item.categoryId === params.categoryId) {
					arr.push(item);
				}
				if (origianl.length - 1 === index) {
					resolve(arr);
				}
			});
		})
			.then((productsArray) => {
				if (params.search === undefined) {
					return productsArray;
				} else {
					const arr = [];
					const regExp = new RegExp(params.search, 'ig');

					productsArray.forEach((item, index) => {
						if (item.name.match(regExp) !== null) {
							arr.push(item);
						}
						if (productsArray.length - 1 === index) {
							return arr;
						}
					});

					return arr;
				}
			})
			.then((productsArray) => {
				if (productsArray.length <= 12) {
					return effector.events.currentListParams({
						currentList: productsArray,
						allPages: 1,
						currentPage: params.page,
						totalProducts: productsArray.length,
					});
				} else {
					const allPages = Math.ceil(productsArray.length / 12);
					const arr = [];

					const firstIndex = 12 * params.page - 12;
					const lastIndex = 12 * params.page - 1;

					productsArray.forEach((item, index) => {
						if (index >= firstIndex && index <= lastIndex) {
							arr.push(item);
						}
					});

					return effector.events.currentListParams({
						currentList: arr,
						allPages: allPages,
						currentPage: params.page,
						totalProducts: productsArray.length,
					});
				}
			})
			.catch(() => {
				return effector.events.currentListParams({ currentList: [] });
			});
	};

	useEffect(() => {
		if ($productsList.data.products.length !== 0) {
			changeCurrentList($productsList.data.products, {
				page: Number(urlParams.page),
				categoryId: Number(urlParams.category),
				search: urlParams.search,
			});
		}
	}, [urlParams.page, urlParams.category, urlParams.search, $productsList.data.products]);

	useEffect(() => {
		const arr = [];

		for (let item of $productsList.data.products) {
			arr.push(item.galleryImg[0].url);
		}

		setImgArr(arr);
	}, [$productsList.data.currentList]);

	return (
		<>
			<Divider />
			{$screen.size ? <CategoryList /> : <MobileHeader />}
			<Divider />
			{$productsList.loading ? (
				<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
					{[1, 2, 3, 4, 5, 6].map((item, index) => {
						return (
							<div key={`SKELETON${index}`}>
								<Skeleton
									variant='rect'
									height={300}
									width={$screen.size ? 400 : 300}
									className={classes.skeleton}
								/>
								<Skeleton variant='text' height={50} className={classes.skeleton} />
							</div>
						);
					})}
				</div>
			) : (
				<div
					style={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						minHeight: '65vh',
					}}>
					<ItemList />
					<PaginationList />

					<div style={{ position: 'absolute', zIndex: '-10', opacity: 0 }}>
						{imgArr.map((item, index) => {
							return (
								<img
									key={`FIRST${index}`}
									src={item}
									alt='pre'
									style={{ width: '0px', height: '0px' }}
								/>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};
