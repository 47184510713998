import React from 'react';
import { useStore } from 'effector-react';
import { effector } from '../global/effector/index';
import { makeStyles } from '@material-ui/styles';
import idealluxImg from '../../images/ideal_lux_logo.png';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
	block: {
		padding: '20px 30px',
		width: '300px',
		height: '300px',
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
	},
	header: {
		fontSize: '30px',
		textAlign: 'center',
		fontFamily: 'Mont-bold',
		marginBottom: '20px',
	},
});

export const IdealLux = () => {
	const classes = useStyles();

	const { $screen } = useStore(effector.stores);

	return (
		<div>
			<div style={{ position: 'relative' }}>
				<img
					style={{ width: '100%' }}
					src='https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/static-img%2Fideallux.jpg?alt=media&token=83f5c014-ab90-47b3-b7bb-68098fce45c2'
					alt='ideallux'
				/>
				<div
					style={{
						display: $screen.size ? 'flex' : 'none',
						justifyContent: 'flex-start',
						alignItems: 'flex-end',
						fontFamily: 'Mont-bold',
						fontSize: '40px',
						position: 'absolute',
						height: '100%',
						width: '100%',
						bottom: '30px',
						left: '30px',
						color: '#fff',
					}}>
					IDEAL LUX
				</div>
			</div>
			<div style={{ margin: $screen.size ? '50px' : '20px' }}>
				<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
					<img src={idealluxImg} alt='magres-logo' />
				</div>
				<div style={{ fontSize: '20px' }}>
					Основанная в 1974 году, компания Ideal Lux родилась как маленькая реальность, которой
					руководила интуиция создания актуального, но в то же время доступного для широких слоев
					населения продукта - цель, которую мы преследуем и сегодня. Наша цель - сохранить в рамках
					одного уникального бренда максимально широкое разнообразие стилей, адекватных тенденциям
					отрасли, которые могут варьироваться от классического декоративного стиля до современного,
					от продуктов для улицы до технических изделий. Внимание к людям занимает центральное
					место. Мы слушаем, чтобы лучше понять их потребности, чтобы ответить не только на функцию,
					но и, что самое важное, на стиль жизни. Работа на пяти различных континентах придает нам
					международную особенность, которая не изменила наш образ существования и деятельности как
					компании: энтузиазм в отношении хорошо сделанных вещей, отношение к прозрачности и
					привычка к простоте.
				</div>
			</div>
			<div style={{ margin: $screen.size ? '50px' : '50px 20px' }}>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item>
							<Paper className={classes.block}>
								<div className={classes.header}>1969</div>
								<div>
									Джованбаттиста Страно начинает свою деятельность производителя и торговца люстрами
									почти случайно. Он превращает в готовый продукт партию муранских стекол, которые
									он обнаружил в товаре, купленном на аукционе.
								</div>
							</Paper>
						</Grid>
						<Grid item>
							<Paper className={classes.block}>
								<div className={classes.header}>1971</div>
								<div>
									Работая под именем Linea Esse вместе со своим братом, он развивает свою клиентуру,
									состоящую в основном из оптовиков, на территории Италии. Это подразумевает
									способность сочетать специфику ремесленника с необходимостью массового
									производства.
								</div>
							</Paper>
						</Grid>
						<Grid item>
							<Paper className={classes.block}>
								<div className={classes.header}>1974</div>
								<div>
									Linea Esse становится Ideal Lux, более международным именем, которое проектируется
									для развития за рубежом.
								</div>
							</Paper>
						</Grid>
						<Grid item>
							<Paper className={classes.block}>
								<div className={classes.header}>1976</div>
								<div>
									Ideal Lux принимает участие в миланской выставке, где впервые взаимодействует с
									зарубежными рынками. В частности, компания укрепит продуктивное сотрудничество с
									клиентами из Саудовской Аравии.
								</div>
							</Paper>
						</Grid>
						<Grid item>
							<Paper className={classes.block}>
								<div className={classes.header}>1990</div>
								<div>
									Война в Персидском заливе подавляет арабский рынок, заставляя компанию обращаться
									к европейскому, в частности к таким странам, как Германия, Франция, Англия и
									Восток Европы.
								</div>
							</Paper>
						</Grid>
						<Grid item>
							<Paper className={classes.block}>
								<div className={classes.header}>2007</div>
								<div>
									Открытие первого магазина прямой продажи в Мирано. В том же году в компанию
									приходят сыновья.
								</div>
							</Paper>
						</Grid>
						<Grid item>
							<Paper className={classes.block}>
								<div className={classes.header}>2008</div>
								<div>
									Расширение сети продаж в Италии и за рубежом. Также представлен первый ассортимент
									товаров для активного отдыха.
								</div>
							</Paper>
						</Grid>
						<Grid item>
							<Paper className={classes.block}>
								<div className={classes.header}>2013</div>
								<div>
									Создание графического отдела. Первое участие в выставке Maison&Objet в Париже.
								</div>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};
