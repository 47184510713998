import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { effector } from '../../effector/index';
import { makeStyles } from '@material-ui/styles';
import { useStore } from 'effector-react';

import TouchAppIcon from '@material-ui/icons/TouchApp';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { Notification } from '../../../../presets/notification/index';

const useStyles = makeStyles({
	contract: {
		marginBottom: '20px',
		position: 'relative',

		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'underline',
			color: '#acacac',
		},
	},

	bold: {
		fontFamily: 'Mont-semi',
	},

	header: {
		textAlign: 'center',
		marginTop: '20px',
		fontFamily: 'Mont-semi',
	},
});

export const Contract = () => {
	const classes = useStyles();

	const [error, setError] = useState(false);

	const [modal, setModal] = useState({ modal: false, render: false });

	const { $user, $cart, $check } = useStore(effector.stores);

	const formatter = new Intl.NumberFormat({
		style: 'currency',
		currency: 'UZS',
		minimumFractionDigits: 0,
	});

	return (
		<>
			<div
				style={{
					margin: '20px',
					padding: '0 20px',
				}}>
				<div
					className={classes.contract}
					onClick={() => {
						if ($user.firstName === '') {
							setError(true);
						} else {
							setModal({ modal: true, render: true });
						}
					}}>
					<TouchAppIcon style={{ marginRight: '5px', top: '5px', position: 'relative' }} />
					Ознакомиться с договором
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<div>Ознакомлен(а) и согласен(на) с договором</div>
					<Checkbox
						color='primary'
						onClick={(e) => effector.events.contractIsRead(e.target.checked)}
					/>
				</div>
			</div>
			<Dialog
				maxWidth='md'
				disableBackdropClick
				disableEscapeKeyDown
				open={modal.modal}
				onExited={() => {
					setModal({ modal: false, render: false });
				}}>
				<DialogContent>
					<div style={{ fontSize: '11px' }} id='print-content'>
						<div style={{ fontFamily: 'Mont-semi', textAlign: 'center', marginBottom: '20px' }}>
							СЧЕТ - ДОГОВОР
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
							<span>г. Ташкент</span>
							<span>
								{(() => {
									const date = new Date();
									return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
								})()}
							</span>
						</div>
						<div>
							<span className={classes.bold}>OOO «AZIRUS»</span> именуемое в дальнейшем ПРОДАВЕЦ, в
							лице Продавца-консультанта, действующего на основании Доверенности №1 от 30.08.2020, с
							одной стороны и{' '}
							<span className={classes.bold}>
								{$user.lastName} {$user.firstName} {$user.middleName}
							</span>{' '}
							именуемый в дальнейшем ПОКУПАТЕЛЬ, заключили настоящий договор о нижеследующем:
						</div>
						<div className={classes.header}>1. ПРЕДМЕТ ДОГОВОРА.</div>
						<div style={{ marginBottom: '20px' }}>
							1.1. Предметом настоящего договора являются взаимные обязательства сторон на поставку
							и оплату товаров :
						</div>
						<div>
							<table border='1' width='100%' style={{ borderCollapse: 'collapse' }}>
								<tr align='center' style={{ height: '50px', fontFamily: 'Mont-regular' }}>
									<td>№ п./п.</td>
									<td>Наименование товара</td>
									<td>Ед. измерения</td>
									<td>Кол-во</td>
									<td>Стоимость</td>
									<td>НДС 15%</td>
									<td>Всего</td>
								</tr>
								{$cart.data.map((item, index) => {
									return (
										<tr key={`${item.id}${index}`} align='center' style={{ height: '50px' }}>
											<td>{index + 1}</td>
											<td>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-evenly',
														margin: '5px 0',
													}}>
													<img
														style={{ height: '70px', borderRadius: '5px' }}
														src={item.previewImg.url}
														alt={item.previewImg.name}
													/>{' '}
													{item.name}
													<br />
													{item.price.materialName}
													<br />
													{item.price.materialColorName}
												</div>
											</td>
											<td>шт</td>
											<td>{item.price.qty}</td>
											<td>
												{formatter.format(
													Number(item.price.cost * item.price.qty) -
														Math.ceil(Number(item.price.cost * item.price.qty) / 1.15)
												)}{' '}
												СУМ
											</td>
											<td>
												{formatter.format(
													Math.ceil(Number(item.price.cost * item.price.qty) / 1.15)
												)}{' '}
												СУМ
											</td>
											<td>{formatter.format(item.price.cost)} СУМ</td>
										</tr>
									);
								})}
								<tr className={classes.bold} style={{ height: '30px' }}>
									<td></td>
									<td colspan='5' style={{ paddingLeft: '20px' }}>
										ИТОГО:
									</td>
									<td style={{ textAlign: 'center' }}>{formatter.format($check.finalPrice)} СУМ</td>
								</tr>
							</table>
						</div>
						<div className={classes.header}>2. УСЛОВИЯ ПОСТАВКИ И РАСЧЕТОВ.</div>
						<div>
							2.1. Условия поставки – бесплатная доставка и установка за счет ПРОДАВЦА, в черте
							города Ташкент.
						</div>
						<div>
							2.2. Отгрузка товара осуществляется не позднее 3 (трех) дней с момента поступления
							денежных средств на расчетный счет ПРОДАВЦА.
						</div>
						<div>
							2.3. ПОКУПАТЕЛЬ производит предоплату в размере не менее 100% от суммы договора на
							расчетный счет ПРОДАВЦА не позднее 3 (трех) банковских дней с момента подписания
							настоящего договора,
						</div>
						<div>
							2.4. ПРОДАВЕЦ имеет право реализовать Товар другим покупателям, если денежные средства
							не поступили в течение 3(трех) банковских дней с момента подписания договора.
						</div>
						<div className={classes.header}>3. КАЧЕСТВО ТОВАРА И ГАРАНТИЯ.</div>
						<div>
							3.1. ПРОДАВЕЦ гарантирует, что качество Товара, поставляемого по настоящему Договору,
							соответствует сертификату происхождения, выданного на имя завода-изготовителя
							Вильнюсской торгово-промышленной и ремесленной палатой.
						</div>
						<div>
							3.2. ПРОДАВЕЦ предоставляет Гарантию на Товар по настоящему договору на срок до 12
							месяцев со дня его отгрузки, при условии четкого следования ПОКУПАТЕЛЕМ норм и правил
							эксплуатации Товара, а также его использования только по назначению.
						</div>
						<div className={classes.header}>4.ОТВЕТСТВЕННОСТЬ СТОРОН.</div>
						<div>
							4.1. В случае просрочки отпуска товара ПРОДАВЕЦ уплачивает ПОКУПАТЕЛЮ пеню в размере
							0,1% от неисполненной части обязательств за каждый день просрочки, но не более 20% от
							стоимости недопоставленных товаров.
						</div>
						<div>
							4.2. За необоснованный отказ, от оплаты за полученный товар ПОКУПАТЕЛЬ уплачивает
							ПРОДАВЦУ штраф в размере 50% от суммы, которую он отказался или уклонился уплатить, а
							также покрывает все расходы, и убытки, связанные с доставкой Товара.
						</div>
						<div>
							4.3. Если отпущенные товары не соответствуют обязательным условиям по ГОСТ или ТУ,
							ассортименту и сортности, ПРОДАВЕЦ обязуется заменить на товар надлежащего качества.
						</div>
						<div>
							4.4. ПОКУПАТЕЛЬ несет ответственность за подлинность ксерокопий удостоверения личности
							и других предоставляемых данных (ИНН).
						</div>
						<div>
							4.5. Независимо от уплаты неустойки (штрафы, пени) виновная сторона возмещает другой
							стороне причиненные в связи с этим убытки и исполняет условия договора.
						</div>
						<div>
							4.6. Стороны прикладывают максимальные усилия, чтобы устранить возникшие разногласия
							путем переговоров и предъявления претензий. На претензию стороны обязаны дать ответ в
							течение одного месяца. Если в результате переговоров стороны не придут к решению, спор
							решается в судебном порядке в Экономическом суде г.Ташкент.
						</div>
						<div>
							4.7. Пункты, предусматривающие ответственность сторон за ненадлежащее выполнение
							условий настоящего договора вступают в силу, если одна из сторон направляет другой
							стороне письменную претензию о нарушении условий договора.
						</div>
						<div className={classes.header}>5. ФОРС-МАЖОР.</div>
						<div>
							5.1.Стороны не несут ответственность, предусмотренную в договоре, если невозможность
							выполнения ими условий Договора наступила в силу форс-мажорных обстоятельств, в том
							числе наводнений, землетрясений, военных действий, распоряжений правительства и других
							обстоятельств непреодолимой силы, находящихся вне контроля участников договора.
						</div>
						<div className={classes.header}>6. ОСОБЫЕ УСЛОВИЯ.</div>
						<div>
							6.1. Во всем, что не предусмотрено договором, стороны руководствуются Гражданским
							Кодексом, ХПК, а также Законом о договорно-правовой базе деятельности хозяйствующих
							субъектов Республики Узбекистан.
						</div>
						<div>
							6.2. Срок действия настоящего договора: с момента подписания до полного исполнения
							сторонами всех принятых на себя обязательств.
						</div>
						<div>6.3. Договор может быть расторгнут досрочно по взаимному соглашению сторон.</div>
						<div>
							6.4. Договор составлен в двух экземплярах на русском языке, по одному для каждой из
							сторон, причем оба текста идентичны и имеют одинаковую юридическую силу. Любые
							изменения и дополнения к настоящему Договору действительны лишь в том случае, если они
							оформлены в письменном виде и подписаны обеими сторонами. Ни одна из сторон не вправе
							передавать свои права и обязательства по настоящему Договору третьей стороне.
						</div>
						<div className={classes.header}>
							7. ЮРИДИЧЕСКИЕ АДРЕСА И БАНКОВСКИЕ РЕКВИЗИТЫ СТОРОН.
						</div>
						<div>
							7.1. При изменении юридического адреса или обслуживающего банка стороны обязаны в 3-х
							дневный срок уведомить об этом другую сторону.
						</div>
					</div>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => setModal({ modal: false, render: true })} color='primary'>
						Закрыть
					</Button>
				</DialogActions>
			</Dialog>
			<Notification
				text='Заполните информацию о покупателе'
				onExited={() => setError(false)}
				type='error'
				state={error}
			/>
		</>
	);
};
