import { createStore } from 'effector';
import * as effects from './effects';
import * as events from './events';

export const $product = createStore({
	data: {
		id: '',
		categoryId: '',
		categoryName: '',
		name: '',
		price: [
			{ cost: '', credit: '', img: { url: '', name: '' }, materialName: '', materialColorName: '' },
		],
		file: {
			name: '',
			url: '',
		},
		features: [],
		description: '',
		size: {
			height: '',
			depth: '',
			length: '',
		},
		options: [],
		previewImg: {
			url: '',
			name: '',
		},
		galleryImg: [{ url: '', name: '' }],
	},
	loading: false,
	error: null,
})
	.on(effects.getProductById.pending, (state, pending) => {
		return {
			...state,
			loading: pending,
		};
	})
	.on(effects.getProductById.done, (state, response) => {
		return {
			...state,
			data: response.result,
			error: null,
		};
	})
	.on(effects.getProductById.fail, (state, response) => {
		return {
			...state,
			error: response.error,
		};
	})
	.on(events.getProduct, (state, product) => {
		return {
			...state,
			data: product,
		};
	})
	.reset(events.resetProduct);

export const $selected = createStore({ index: 0, qty: 1 })
	.on(events.setSelected, (state, index) => {
		return {
			...state,
			index: index,
		};
	})
	.on(events.setQty, (state, qty) => {
		return {
			...state,
			qty: qty,
		};
	});
