import { get } from './requests';

export const getCategoryList = () => {
	return get(
		'https://us-central1-baltichome-1cd35.cloudfunctions.net/app/site/global/get-category-list'
	);
};

export const getPromo = () => {
	return get('https://us-central1-baltichome-1cd35.cloudfunctions.net/app/site/promo/get-promo');
};