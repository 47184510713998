import { createStore } from 'effector';
import * as events from './events';
import * as effects from './effects';

export const $cart = createStore({ data: [] })
	.on(events.addProductToCart, (state, product) => {
		localStorage.setItem('cart', JSON.stringify([...state.data, product]));
		return {
			data: [...state.data, product],
		};
	})
	.on(events.editQty, (state, obj) => {
		const product = JSON.parse(JSON.stringify(state.data[obj.index]));

		product.price.qty = Number(product.price.qty) + obj.qty;

		state.data.splice(obj.index, 1, product);

		localStorage.setItem('cart', JSON.stringify([...state.data]));

		return {
			data: [...state.data],
		};
	})
	.on(events.initialCart, (state, obj) => {
		const arr = JSON.parse(obj);

		return {
			data: [...arr],
		};
	})
	.on(events.deleteItemInCart, (state, index) => {
		const arr = [...state.data];

		arr.splice(index, 1);

		localStorage.setItem('cart', JSON.stringify([...arr]));

		return {
			data: arr,
		};
	})
	.reset(events.resetCart);

export const $user = createStore({ firstName: '', lastName: '', middleName: '', email: '' })
	.on(events.userInfo, (state, obj) => {
		localStorage.setItem('user', JSON.stringify(obj));

		return {
			...obj,
		};
	})
	.on(events.initialUser, (state, obj) => {
		const user = JSON.parse(obj);

		return {
			...user,
		};
	});

export const $address = createStore({ address: '', zip: '', phone: '' })
	.on(events.addressInfo, (state, obj) => {
		localStorage.setItem('address', JSON.stringify(obj));
		return {
			...obj,
		};
	})
	.on(events.initialAddress, (state, obj) => {
		const address = JSON.parse(obj);

		return {
			...address,
		};
	});

export const $payment = createStore({ card: '', exp: '' }).on(events.paymentInfo, (state, obj) => {
	return {
		...obj,
	};
});

export const $contract = createStore({ read: false }).on(
	events.contractIsRead,
	(state, boolean) => {
		return {
			read: boolean,
		};
	}
);

export const $check = createStore({ finalPrice: '', vat: '', discount: '' }).on(
	events.checkInfo,
	(state, obj) => {
		return {
			...obj,
		};
	}
);

export const $token = createStore({
	loading: false,
	error: null,
	data: { id: '', token: '', phone: '' },
})
	.on(effects.getToken.pending, (state, pending) => {
		return {
			...state,
			loading: pending,
		};
	})
	.on(effects.getToken.done, (state, response) => {
		return {
			...state,
			data: response.result,
			error: null,
		};
	})
	.on(effects.getToken.fail, (state, response) => {
		return {
			...state,
			error: response.error.message,
		};
	});

export const $place = createStore({ loading: false, error: null, data: false })
	.on(effects.placeOrder.pending, (state, pending) => {
		return {
			...state,
			loading: pending,
		};
	})
	.on(effects.placeOrder.done, (state, response) => {
		localStorage.setItem('cart', []);

		return {
			...state,
			data: response.result,
			error: null,
		};
	})
	.on(effects.placeOrder.fail, (state, response) => {
		return {
			...state,
			error: response.error.message,
		};
	})
	.reset(events.resetPlace);
