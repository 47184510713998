import axios from 'axios'

export const get = async (url, params) => {
	return axios
		.get(url, { params: { ...params } })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			return response.data.error;
		});
};

export const post = async (url, data) => {
	return axios
		.post(url, { ...data })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			return response.data.error;
		});
};

export const put = async (url, data) => {
	return axios
		.put(url, { ...data })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			return response.data.error;
		});
};

export const del = async (url, data) => {
	return axios
		.delete(url, { data: { ...data } })
		.then((response) => {
			return response.data;
		})
		.catch((response) => {
			return response.data.error;
		});
};
