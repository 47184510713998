import React from 'react';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';

export const NotFound = () => {
	const { $screen } = useStore(effector.stores);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '75vh',
				fontFamily: 'Mont-Thin',
				fontSize: $screen.size ? '40px' : '30px',
			}}>
			<div>Данной страницы не существует или она устарела!</div>
		</div>
	);
};
