import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { effector as globalEffector } from '../../../global/effector/index';
import { effector } from '../../effector/index';

import Button from '@material-ui/core/Button';
import Textfield from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Notification } from '../../../../presets/notification/index';

export const PlaceOrder = () => {
	const { $screen } = useStore(globalEffector.stores);

	const { $user, $address, $payment, $contract, $cart, $token, $check, $place } = useStore(
		effector.stores
	);

	const [error, setError] = useState({ status: false, text: '' });

	const [modal, setModal] = useState({ modal: false, render: false });

	const [watch, setWatch] = useState(0);

	const [code, setCode] = useState('');

	const place = () => {
		if ($user.firstName === '' || $user.lastName === '' || $user.email === '') {
			return setError({ status: true, text: 'Заполните информацию о покупателе' });
		}
		if ($address.address === '' || $address.zip === '' || $address.tel === '') {
			return setError({ status: true, text: 'Укажите адрес доставки' });
		}
		if ($payment.card === '' || $payment.exp === '') {
			return setError({ status: true, text: 'Укажите платежную информацию' });
		}
		if (!$contract.read) {
			return setError({ status: true, text: 'Вы должны быть ознакомлены и согласны с договором' });
		} else {
			setWatch(60);
			effector.effects.getToken({ card: $payment.card, exp: $payment.exp });
			return setModal({ modal: true, render: true });
		}
	};

	const stopWatch = () => {
		setTimeout(() => setWatch(watch - 1), 1000);
	};

	useEffect(() => {
		if (watch > 0 && modal.modal) {
			stopWatch();
		}
		if (watch === 0) {
			setModal({ modal: false, render: true });
			setCode('');
		}
	}, [watch]);

	useEffect(() => {
		if ($token.error) {
			setModal({ modal: false, render: false });
			setError({ status: true, text: $token.error });
		}
	}, [$token.error]);

	useEffect(() => {
		setModal({ modal: false, render: false });
		if ($place.data) {
			setCode('');
		}
		if ($place.error) {
			setError({ status: true, text: $token.error });
		}
	}, [$place.data, $place.error]);

	useEffect(() => console.log($place.error))

	const placeOrder = () => {
		effector.effects.placeOrder({
			id: $token.data.id,
			token: $token.data.token,
			code: code,
			price: Number($check.finalPrice),
			order: {
				...$user,
				...$address,
				...$check,
				nonVatPrice: $check.finalPrice - $check.vat,
				products: $cart.data,
			},
		});
	};

	const loader = (state) => {
		return !state ? (
			false
		) : (
			<div
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					backgroundColor: '#fff',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					zIndex: '100',
					boxSizing: 'border-box',
				}}>
				<CircularProgress />
			</div>
		);
	};

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px' }}>
				<Button
					disabled={$cart.data.length === 0}
					variant='contained'
					color='primary'
					style={{ width: $screen.size ? '' : '100%' }}
					onClick={place}>
					Оплатить
				</Button>
			</div>
			<Notification
				text={error.text}
				onExited={() => setError({ status: false, text: '' })}
				type='error'
				state={error.status}
			/>
			{!modal.render ? (
				''
			) : (
				<Dialog open={modal.modal} onExited={() => setModal({ modal: false, render: false })}>
					<DialogTitle>Оплата</DialogTitle>
					<Divider />
					<div style={{ margin: '20px 20px', position: 'relative' }}>
						{loader($token.loading)}
						{loader($place.loading)}
						<DialogContentText>
							Вам отправлен код подтверждения <br />
							на номер {$token.data.phone} <br />
							Осталось {watch} сек
						</DialogContentText>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<Textfield
								label='Проверочный код'
								variant='outlined'
								style={{ margin: $screen.size ? '0 10px 0 0' : '0 0 20px 0' }}
								value={code}
								onChange={(e) => setCode(e.target.value)}
							/>
							<Button color='primary' variant='contained' onClick={placeOrder}>
								Подтвердить
							</Button>
						</div>
					</div>
					<Divider />
					<DialogActions>
						<Button
							onClick={() => {
								setModal({ modal: false, render: true });
								setCode('');
							}}
							color='primary'>
							Закрыть
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};
