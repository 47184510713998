import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Menu } from '../global/components/menu/index';
import { NotFound } from '../global/components/notFound/index';
import { Landing } from '../landing/index';
import { ProductsList } from '../productsList/index';
import { ProductsPage } from '../productsPage/index';
import { Cart } from '../cart/index';
import { Magres } from '../static/magres';
import { IdealLux } from '../static/ideallux';
// import { Credit } from '../static/credit';
// import { Order } from '../static/order';

export const RouterContainer = () => {
	return (
		<Switch>
			<Route path='/' exact component={Landing} />
			<Route path='/menu' exact component={Menu} />
			<Route path='/products/page=:page/category=:category/' exact component={ProductsList} />
			<Route path='/products/page=:page/category=:category/search=:search/' exact component={ProductsList} />
			<Route path='/products/id=:id' exact component={ProductsPage} />
			<Route path='/cart' exact component={Cart} />
			{/* <Route path='/credit' exact component={Credit} /> */}
			{/* <Route path='/order' exact component={Order} /> */}
			<Route path='/magres' exact component={Magres} />
			<Route path='/ideallux' exact component={IdealLux} />
			<Route path='*' exact component={NotFound} />
		</Switch>
	);
};
