import React, { useEffect } from 'react';
import { effector } from './effector/index';
import { useStore } from 'effector-react';
import { makeStyles } from '@material-ui/styles';
import { effector as globalEffector } from '../global/effector/index';

import Skeleton from '@material-ui/lab/Skeleton';

import { HeaderProduct } from './components/headerProduct/index';
import { ListOfProducts } from './components/listOfProducts/index';
import { BigPromo } from './components/bigPromo/index';
import { HalfPromo } from './components/halfPromo/index';
import { CategoryList } from './components/categoryList/index'

const useStyles = makeStyles({
	skeleton: {
		borderRadius: '5px',
		margin: '20px',
	},
});

export const Landing = () => {
	const classes = useStyles();

	const { $landing } = useStore(effector.stores);

	const { $screen } = useStore(globalEffector.stores);

	useEffect(() => {
		if ($landing.data.header.link === '') {
			effector.effects.getLandingInfo();
		}
	}, []);

	return (
		<div style={{ position: 'relative' }}>
			{$landing.loading ? (
				<>
					<Skeleton variant='text' height={50} className={classes.skeleton} />
					<Skeleton variant='rect' height={400} className={classes.skeleton} />
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexWrap: $screen.size ? 'none' : 'wrap',
						}}>
						{[1, 2, 3].map((item, index) => {
							return (
								<div key={`${index}SKELETON`}>
									<Skeleton variant='rect' height={200} width={250} className={classes.skeleton} />
									<Skeleton variant='text' height={30} width={150} className={classes.skeleton} />
								</div>
							);
						})}
					</div>
					<Skeleton variant='rect' height={400} className={classes.skeleton} />
					<div style={{ width: '100%', display: 'flex', flexWrap: $screen.size ? 'none' : 'wrap' }}>
						<Skeleton variant='rect' height={300} width={'50%'} className={classes.skeleton} />
						<Skeleton variant='rect' height={300} width={'50%'} className={classes.skeleton} />
					</div>
				</>
			) : (
				<>
					<HeaderProduct />
					<CategoryList />
					<BigPromo />
					<ListOfProducts />
					<HalfPromo />
				</>
			)}
		</div>
	);
};
