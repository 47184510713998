import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { effector as globalEffector } from '../global/effector/index';
import { effector } from './effector/index';

import Divider from '@material-ui/core/Divider';

import { ItemList } from './components/itemList/index';
import { Address } from './components/address/index';
import { UserInfo } from './components/userInfo/index';
import { Contract } from './components/contract/index';
import { CheckCounter } from './components/checkCounter/index';
import { Payment } from './components/payment/index';
import { PlaceOrder } from './components/placeOrder/index';
import { Notification } from '../../presets/notification/index';

export const Cart = () => {
	const { $screen } = useStore(globalEffector.stores);

	const { $cart, $place } = useStore(effector.stores);

	const [success, setSuccess] = useState(false);

	useEffect(() => {
		if ($place.data) {
			effector.events.resetCart();
			effector.events.resetPlace();
			setSuccess(true);
		}
	}, [$place.data]);

	return (
		<div>
			<Divider />
			<div
				style={{
					textAlign: 'center',
					fontFamily: 'Mont-thin',
					fontSize: $screen.size ? '40px' : '30px',
					padding: '20px',
				}}>
				Корзина
			</div>
			<Divider />
			<div style={{ display: 'flex', width: '100%', flexWrap: $screen.size ? 'nowrap' : 'wrap' }}>
				<div style={{ width: '100%' }}>
					<ItemList />
				</div>
				<div
					style={{
						position: 'relative',
						width: $screen.size ? '' : '100%',
						display: $cart.data.length === 0 ? 'none' : 'block',
					}}>
					<div
						style={{
							width: $screen.size ? '450px' : '100%',
							position: $screen.size ? 'sticky' : 'relative',
							top: $screen.size ? '80px' : '0px',
						}}>
						<UserInfo />
						<Address />
						<Payment />
						<Contract />
						<CheckCounter />
						<PlaceOrder />
					</div>
				</div>
			</div>
			<Notification
				text='Заказ успешно создан! Письмо с подтверждением отправлено вам на почту! Наш мендежер свяжется с вами в ближашее время!'
				onExited={() => setSuccess(false)}
				type='success'
				state={success}
			/>
		</div>
	);
};
