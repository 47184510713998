import { get } from './requests';

export const getProductList = (data) => {
	return get(
		'https://us-central1-baltichome-1cd35.cloudfunctions.net/app/site/products/get-product-list'
	);
};

export const getProductById = (params) => {
	return get(
		`https://us-central1-baltichome-1cd35.cloudfunctions.net/app/site/products/get-product-by-id/${params}`
	);
};
