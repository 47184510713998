import { createEvent } from 'effector';

export const addProductToCart = createEvent();

export const editQty = createEvent();

export const initialCart = createEvent();

export const resetCart = createEvent();

export const deleteItemInCart = createEvent();

export const userInfo = createEvent();

export const initialUser = createEvent();

export const addressInfo = createEvent();

export const initialAddress = createEvent();

export const paymentInfo = createEvent();

export const contractIsRead = createEvent();

export const checkInfo = createEvent();

export const resetPlace = createEvent()
