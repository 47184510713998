import React, { useState } from 'react';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';

import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MobileStepper from '@material-ui/core/MobileStepper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export const Carousel = () => {
	const [index, setIndex] = useState(0);

	const [imgLoad, setImageLoad] = useState(false);

	const [modal, setModal] = useState({ render: false, modal: false, index: 0 });

	const { $product } = useStore(effector.stores);
	return (
		<>
			<div style={{ opacity: '0', zIndex: '-1', position: 'absolute' }}>
				{$product.data.galleryImg.map((item, index) => {
					return <img key={`${index}IMGES`} style={{ height: '0px' }} src={item.url} alt={item.name} />;
				})}
			</div>
			<div style={{ width: '100%', height: '100%', margin: '20px 0' }}>
				<div style={{ position: 'relative' }}>
					<div
						style={{
							position: 'absolute',
							display: imgLoad ? 'none' : 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							height: '100%',
							backgroundColor: '#ffffff94',
						}}>
						<CircularProgress />
					</div>
					<img
						onLoad={() => setImageLoad(true)}
						onClick={() => setModal({ render: true, modal: true, index: index })}
						style={{ width: '100%', cursor: 'pointer' }}
						src={$product.data.galleryImg[index].url}
						alt={$product.data.galleryImg[index].name}
					/>
				</div>
				<MobileStepper
					style={{ backgroundColor: '#fff' }}
					position='static'
					steps={$product.data.galleryImg.length}
					activeStep={index}
					nextButton={
						<Button
							onClick={() => {
								setImageLoad(false);
								index === $product.data.galleryImg.length - 1 ? setIndex(0) : setIndex(index + 1);
							}}>
							<ArrowRightIcon />
						</Button>
					}
					backButton={
						<Button
							onClick={() => {
								setImageLoad(false);
								index === 0 ? setIndex($product.data.galleryImg.length - 1) : setIndex(index - 1);
							}}>
							<ArrowLeftIcon />
						</Button>
					}
				/>
				{!modal.render ? (
					''
				) : (
					<Dialog open={modal.modal} onClose={() => setModal({ render: false, modal: false })}>
						<DialogContent>
							<img
								style={{ width: '100%' }}
								src={$product.data.galleryImg[index].url}
								alt={$product.data.galleryImg[index].name}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setModal({ render: true, modal: false })} color='primary'>
								Закрыть
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</div>
		</>
	);
};
