import { createStore } from 'effector';
import * as events from './events';
import * as effects from './effects';

export const $screen = createStore({ size: true }).on(events.checkScreenSize, (state, boolean) => {
	return {
		size: boolean,
	};
});

export const $categoryList = createStore({ loading: false, error: null, data: [] })
	.on(effects.getCategoryList.pending, (state, pending) => {
		return {
			...state,
			loading: pending,
		};
	})
	.on(effects.getCategoryList.done, (state, response) => {
		return {
			...state,
			data: response.result,
			error: null,
		};
	})
	.on(effects.getCategoryList.fail, (state, response) => {
		return {
			...state,
			error: response.error,
		};
	});

export const $promo = createStore({
	status: false,
	data: { name: '', url: '' },
	loading: false,
	error: null,
})
	.on(effects.getPromo.pending, (state, pending) => {
		return {
			...state,
			loading: pending,
		};
	})
	.on(effects.getPromo.done, (state, response) => {
		return !response.result
			? { ...state, error: null }
			: { ...state, status: true, data: response.result, error: null };
	})
	.on(effects.getPromo.fail, (state, response) => {
		return {
			...state,
			error: response.result,
		};
	})
	.on(events.closePromo, (state) => {
		sessionStorage.setItem('promo', false);
		return {
			...state,
			status: false,
		};
	});
