import { post } from './requests';

export const getToken = (data) => {
	return post('https://us-central1-baltichome-1cd35.cloudfunctions.net/app/site/orders/get-token', data);
};

export const placeOrder = (data) => {
	return post(
		'https://us-central1-baltichome-1cd35.cloudfunctions.net/app/site/orders/verify-card-and-pay',
		data
	);
};
