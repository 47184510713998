import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { effector as globalEffector } from '../../../global/effector/index';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';

import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles({
	block: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		cursor: 'pointer',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationThickness: '1px',
		},
	},
	blockImg: {
    width: '100%'
	},
	blockText: {
		fontFamily: 'Mont-thin',
		fontSize: '40px',
		textAlign: 'center',
    margin: '20px 0'
	},
});

export const CategoryList = () => {
	const classes = useStyles();

	const { $screen } = useStore(globalEffector.stores);

	const history = useHistory();

	const categoryArray1 = [
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/preview-img%2Fatlantic-pre.jpg?alt=media&token=6a5001d4-4229-41c7-8434-c2ff2e59b504',
			name: 'Диваны',
			link: '31',
		},
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/preview-img%2Fmagre33-pre.jpg?alt=media&token=0097c5e8-60a6-40c2-b0be-0b164627a710',
			name: 'Уголки',
			link: '46',
		},
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/gallery-img%2Flaura-armchair-4.jpg?alt=media&token=531d58ac-6b7d-4fd7-aa90-253da44ae507',
			name: 'Кресла',
			link: '137',
		},
	];

	const categoryArray2 = [
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/preview-img%2Flukas-pre.jpg?alt=media&token=386a5cc3-c8c4-4adc-b7ca-3f27dfe6c962',
			name: 'Кушетки',
			link: '133',
		},
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/preview-img%2Fpentagon-table-pre.png?alt=media&token=dfbd8de9-d87f-46fc-874b-dccffaac7eac',
			name: 'Столики',
			link: '45',
		},
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/preview-img%2Fluka-poufik-pre.jpg?alt=media&token=de040b90-bb00-4878-95a5-43aa5fbb128b',
			name: 'Пуфы',
			link: '44',
		},
	];

	const categoryArray3 = [
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/preview-img%2Fleaves-sp12-pre.jpg?alt=media&token=8d7866aa-90e7-469d-b046-f1696300ece4',
			name: 'Люстры',
			link: '47',
		},
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/preview-img%2Fluxury-ap1-pre.jpg?alt=media&token=8c3ac8ff-80c3-4886-883a-6714bfcd664c',
			name: 'Светильники',
			link: '166',
		},
		{
			img: 'https://firebasestorage.googleapis.com/v0/b/baltichome-1cd35.appspot.com/o/preview-img%2Fdaddy-pt1-cromo-pre.jpg?alt=media&token=ca19206f-4db1-45e4-9555-02090ffbff51',
			name: 'Торшеры',
			link: '158',
		},
	];

	return (
		<div>
			<div style={{ display: 'flex', flexWrap: $screen.size ? 'nowrap' : 'wrap', width: '100%' }}>
				{categoryArray1.map((item, index) => {
					return (
						<div
							key={`${index}Array1`}
							className={classes.block}
							onClick={() => history.push(`/products/page=1/category=${item.link}/`)}>
							<img className={classes.blockImg} src={item.img} alt='img' />
							<div className={classes.blockText}>{item.name}</div>
						</div>
					);
				})}
			</div>
			<div style={{ display: 'flex', flexWrap: $screen.size ? 'nowrap' : 'wrap', width: '100%'}}>
				{categoryArray2.map((item, index) => {
					return (
						<div
							key={`${index}Array2`}
							className={classes.block}
							onClick={() => history.push(`/products/page=1/category=${item.link}/`)}>
							<img className={classes.blockImg} src={item.img} alt='img' />
							<div className={classes.blockText}>{item.name}</div>
						</div>
					);
				})}
			</div>
			<div style={{ display: 'flex', flexWrap: $screen.size ? 'nowrap' : 'wrap', width: '100%' }}>
				{categoryArray3.map((item, index) => {
					return (
						<div
							key={`${index}Array3`}
							className={classes.block}
							onClick={() => history.push(`/products/page=1/category=${item.link}/`)}>
							<img className={classes.blockImg} src={item.img} alt='img' />
							<div className={classes.blockText}>{item.name}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
