import React from 'react';
import { useHistory } from 'react-router-dom';
import { effector } from '../../effector/index';
import { effector as globalEffector } from '../../../global/effector/index';
import { useStore } from 'effector-react';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

export const Header = () => {
	const history = useHistory();

	const { $screen } = useStore(globalEffector.stores);

	const { $product } = useStore(effector.stores);

	return (
		<>
			<Divider />
			<div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
				<Button
					startIcon={<ArrowLeftIcon />}
					style={{ display: $screen.size ? 'flex' : 'none' }}
					onClick={() => history.goBack()}>
					Назад
				</Button>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Breadcrumbs aria-label='breadcrumb'>
						<Button color='inherit' onClick={() => history.push('/')}>
							Главная
						</Button>
						<Button
							color='inherit'
							onClick={() => history.push(`/products/page=1/category=${$product.data.categoryId}`)}>
							{$product.data.categoryName}
						</Button>
						<Typography color='textPrimary'>{$product.data.name}</Typography>
					</Breadcrumbs>
				</div>
			</div>
			<Divider />
		</>
	);
};
