import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { effector } from '../../effector/index';
import { effector as globalEffector } from '../../../global/effector/index';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

export const Details = () => {
	const { $product } = useStore(effector.stores);

	const { $screen } = useStore(globalEffector.stores);

	const [open, setOpen] = useState(false);

	const sizeView = (size) => {
		if (Number(size.depth) === 0 && Number(size.height) === 0 && Number(size.length) === 0) {
			return false;
		} else {
			return (
				<ListItem>
					<ListItemText>
						<div style={{ display: $screen.size ? 'flex' : 'block' }}>
							<div style={{marginRight: '15px'}}>{Number(size.length) === 0 ? false : `Длина - ${size.length} мм`}</div>
							<div style={{marginRight: '15px'}}> {Number(size.depth) === 0 ? false : `Глубина - ${size.depth} мм`}</div>
							<div style={{marginRight: '15px'}}>{Number(size.height) === 0 ? false : `Высота - ${size.height} мм`}</div>
						</div>
					</ListItemText>
					<ListItemIcon>
						<AspectRatioIcon />
					</ListItemIcon>
				</ListItem>
			);
		}
	};

	return (
		<div style={{ marginBottom: '20px' }}>
			<ListItem
				button
				onClick={() => (open ? setOpen(false) : setOpen(true))}
				style={{ border: '1px solid #afafaf' }}>
				<ListItemIcon>
					<InfoIcon />
				</ListItemIcon>
				<ListItemText primary='Особенности' />
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</ListItem>
			<Collapse
				in={open}
				timeout='auto'
				unmountOnExit
				style={{ border: '1px solid #afafaf', borderTop: 'none' }}>
				<List component='div' disablePadding>
					{sizeView($product.data.size)}
					{$product.data.file.url !== '' ? (
						<ListItem>
							<ListItemText>
								<a
									href={$product.data.file.url}
									target='_blank'
									rel='noreferrer'
									download
									style={{ color: 'blue' }}>
									Посмотреть и скачать каталог
								</a>
							</ListItemText>
							<ListItemIcon>
								<SystemUpdateAltIcon />
							</ListItemIcon>
						</ListItem>
					) : (
						false
					)}
					<Divider />
					{$product.data.features.map((item, index) => {
						return (
							<ListItem key={`${index}INFO`}>
								<ListItemText primary={item} />
							</ListItem>
						);
					})}
				</List>
			</Collapse>
		</div>
	);
};
