import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { effector as globalEffector } from '../../../global/effector/index';
import { useStore } from 'effector-react';
import { effector } from '../../effector/index';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
	block: {
		width: '100%',
		position: 'relative',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationThickness: '1px',
			cursor: 'pointer',
		},
	},
	big: {
		position: 'absolute',
		fontFamily: 'Mont-thin',
		fontSize: '40px',
		top: '70px',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationThickness: '1px',
			cursor: 'pointer',
		},
	},
	small: {
		fontFamily: 'Mont-thin',
		fontSize: '30px',
		textAlign: 'center',
		marginTop: '20px',
	},
	arrow: {
		cursor: 'pointer',
	},
});

export const HeaderProduct = () => {
	const classes = useStyles();

	const history = useHistory();

	const { $screen } = useStore(globalEffector.stores);

	const { $landing } = useStore(effector.stores);

	return (
		<div
			className={classes.block}
			style={{ display: $screen.size ? 'flex' : 'none' }}
			onClick={() => history.push($landing.data.header.link)}>
			<div className={clsx({ [classes.big]: $screen.size, [classes.small]: !$screen.size })}>
				{$landing.data.header.title}
			</div>
			<img
				style={{ width: '100%' }}
				src={$landing.data.header.img.url}
				alt={$landing.data.header.img.name}
			/>
		</div>
	);
};
