import React, { useState, useEffect } from 'react';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';
import { useHistory, useParams } from 'react-router-dom';
import { effector as cartEffector } from '../../../cart/effector/index';

import Button from '@material-ui/core/Button';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Badge from '@material-ui/core/Badge';

export const MenuButtons = () => {
	const { $screen } = useStore(effector.stores);

	const history = useHistory();

	const urlParams = useParams();

	const { $cart } = useStore(cartEffector.stores);

	const [menu, setMenu] = useState({
		name: 'МЕНЮ',
		icon: <MenuIcon style={{ fontSize: '30px' }} />,
	});

	useEffect(() => {
		if (history.location.pathname === '/menu') {
			setMenu({ name: 'ЗАКРЫТЬ', icon: <CloseIcon style={{ fontSize: '30px' }} /> });
		}
		if (history.location.pathname !== '/menu') {
			setMenu({ name: 'МЕНЮ', icon: <MenuIcon style={{ fontSize: '30px' }} /> });
		}
	}, [urlParams]);

	const countProducts = () => {
		return $cart.data.reduce((sum, cur) => sum + Number(cur.price.qty), 0)
	};
	
	return (
		<div>
			<Button
				size='small'
				onClick={() =>
					history.location.pathname !== '/menu' ? history.push('/menu') : history.goBack()
				}>
				<span
					style={{
						marginRight: '10px',
						fontFamily: 'Mont-bold',
						display: $screen.size ? 'block' : 'none',
					}}>
					{menu.name}
				</span>
				{menu.icon}
			</Button>
			<Button onClick={() => history.push('/cart')} size='small'>
				<Badge badgeContent={countProducts()} color='primary'>
					<ShoppingBasketIcon />
				</Badge>
			</Button>
		</div>
	);
};
