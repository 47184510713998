import React, { useState, useEffect } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const Notification = (props) => {
	const [notice, setNotice] = useState(false);

	useEffect(() => {
		if (props.state) {
			return setNotice(true);
		}
	}, [props.state]);

	return (
		<Snackbar
			open={notice}
			onClose={() => setNotice(false)}
			autoHideDuration={3000}
			onExited={props.onExited}>
			<MuiAlert severity={props.type} variant='filled'>
				{props.text}
				<IconButton
					size='small'
					aria-label='close'
					color='inherit'
					style={{ marginLeft: '20px' }}
					onClick={() => setNotice(false)}>
					<CloseIcon fontSize='small' />
				</IconButton>
			</MuiAlert>
		</Snackbar>
	);
};
