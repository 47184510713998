import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';
import { useForm } from 'react-hook-form';
import { Notification } from '../../../../presets/notification/index';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const useStyles = makeStyles({
	input: {
		marginBottom: '20px',
		width: '100%',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '5px',
	},
	icon: {
		marginRight: '10px',
	},
});

export const UserInfo = () => {
	const classes = useStyles();

	const { register, handleSubmit, reset } = useForm();

	const [modal, setModal] = useState({ render: false, modal: false });

	const [error, setError] = useState(false);

	const { $user } = useStore(effector.stores);

	const submitInfo = (data) => {
		if (
			data.firstName &&
			data.lastName &&
			data.middleName &&
			data.email &&
			data.email.match(/@/g) !== null
		) {
			effector.events.userInfo(data);
			reset();
			setModal({ render: true, modal: false });
		} else {
			setError(true);
		}
	};

	return (
		<>
			<div
				style={{
					margin: '20px',
					padding: '20px',
					borderRadius: '10px',
					backgroundColor: '#9696961f',
				}}>
				<div
					style={{
						fontFamily: 'Mont-semi',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: '20px',
						fontSize: '20px',
					}}>
					Покупатель
					<Button onClick={() => setModal({ render: true, modal: true })}>
						<EditIcon />
					</Button>
				</div>
				<div>
					<div className={classes.row}>
						{$user.firstName === '' ? (
							''
						) : (
							<>
								<PermIdentityIcon className={classes.icon} /> {$user.firstName} {$user.lastName}
							</>
						)}
					</div>
					<div className={classes.row}>
						{$user.email === '' ? (
							''
						) : (
							<>
								<AlternateEmailIcon className={classes.icon} /> {$user.email}
							</>
						)}
					</div>
				</div>
			</div>
			<Dialog
				maxWidth='xs'
				open={modal.modal}
				onExited={() => setModal({ render: false, modal: false })}>
				<DialogTitle>Покупатель</DialogTitle>
				<Divider />
				<DialogContent style={{ padding: '20px 20px 0 20px' }}>
					<form onSubmit={handleSubmit(submitInfo)}>
						<TextField
							label='Имя'
							className={classes.input}
							variant='outlined'
							name='firstName'
							{...register('firstName')}
						/>
						<TextField
							label='Фамилия'
							className={classes.input}
							variant='outlined'
							name='lastName'
							{...register('lastName')}
						/>
						<TextField
							label='Отчество'
							className={classes.input}
							variant='outlined'
							name='middleName'
							{...register('middleName')}
						/>
						<TextField
							label='Email'
							className={classes.input}
							variant='outlined'
							name='email'
							{...register('email')}
						/>
						<Divider />
						<DialogActions>
							<Button color='primary' onClick={() => setModal({ render: true, modal: false })}>
								Закрыть
							</Button>
							<Button type='submit' color='primary'>
								Сохранить
							</Button>
						</DialogActions>
					</form>
				</DialogContent>
				<Notification
					text='Заполните правильно все поля'
					onExited={() => setError(false)}
					type='error'
					state={error}
				/>
			</Dialog>
		</>
	);
};
