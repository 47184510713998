import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { effector as globalEffector } from '../../../global/effector/index';
import { useStore } from 'effector-react';
import { effector } from '../../effector/index';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
	block: {
		width: '100%',
		transition: '0.2s',
		cursor: 'pointer',
		border: '1px solid transparent',
		paddingBottom: '20px',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationThickness: '1px',
			cursor: 'pointer',
		},
	},
	img: {
		width: '100%',
	},
	header: {
		fontFamily: 'Mont-thin',
		fontSize: '40px',
		textAlign: 'center',
	},
	headerBig: {
		fontFamily: 'Mont-thin',
		fontSize: '40px',
		textAlign: 'center',
	},
	headerSmall: {
		fontFamily: 'Mont-thin',
		fontSize: '30px',
		textAlign: 'center',
	},
});

export const ListOfProducts = () => {
	const classes = useStyles();

	const history = useHistory();

	const { $screen } = useStore(globalEffector.stores);

	const { $landing } = useStore(effector.stores);

	return (
		<>
			<div className={classes.header}>{$landing.data.listTitle}</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-evenly',
					margin: '50px 0',
					width: '100%',
					flexWrap: $screen.size ? 'nowrap' : 'wrap',
					padding: '0 20px',
				}}>
				{$landing.data.list.map((item, index) => {
					return (
						<div
							key={`LIST${index}`}
							className={classes.block}
							onClick={() => history.push(item.link)}>
							<img className={classes.img} src={item.img.url} alt={item.img.name} />
							<div
								className={clsx({
									[classes.headerBig]: $screen.size,
									[classes.headerSmall]: !$screen.size,
								})}>
								{item.title}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};
