import React from 'react';
import { useStore } from 'effector-react';
import { effector } from '../../effector/index';
import { effector as globalEffector } from '../../../global/effector/index';

export const ProductInfo = () => {
	const { $screen } = useStore(globalEffector.stores);

	const { $product } = useStore(effector.stores);

	return (
		<div>
			<div
				style={{
					fontFamily: 'Mont-Thin',
					marginBottom: '20px',
					fontSize: $screen.size ? '40px' : '30px',
				}}>
				{$product.data.name}
			</div>
			<div style={{ marginBottom: '20px' }}>{$product.data.description}</div>
		</div>
	);
};
