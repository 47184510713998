import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useStore } from 'effector-react';
import { effector as globalEffector } from '../global/effector/index';
import { effector as productsEffector } from '../productsList/effector/index';
import { effector } from './effector/index';
import { useParams } from 'react-router-dom';

import Skeleton from '@material-ui/lab/Skeleton';

import { AddToCart } from './components/addToCart/index';
import { Carousel } from './components/carousel/index';
import { ColorSelector } from './components/colorSelector/index';
import { Details } from './components/details/index';
import { ProductInfo } from './components/productInfo/index';
import { Header } from './components/header/index';
import { Counter } from './components/counter/index';

const useStyles = makeStyles({
	block: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		height: '100%',
	},
	skeleton: {
		borderRadius: '5px',
		marginBottom: '20px',
	},
});

export const ProductsPage = () => {
	const classes = useStyles();

	const urlParams = useParams();

	const { $screen } = useStore(globalEffector.stores);

	const { $productsList } = useStore(productsEffector.stores);

	const { $product } = useStore(effector.stores);

	useEffect(() => {
		for (let item of $productsList.data.products) {
			if (String(item.id) === urlParams.id) {
				effector.events.getProduct(item);
				break;
			}
		}

		// effector.effects.getProductById(urlParams.id);
	}, [urlParams.id, $productsList.data.products]);

	useEffect(() => {
		return () => effector.events.resetProduct();
	}, []);

	return (
		<>
			<Header />
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexWrap: $screen.size ? 'nowrap' : 'wrap',
					width: '100%',
				}}>
				<div
					className={classes.block}
					style={{ marginRight: $screen.size ? '20px' : 0, display: 'inherit' }}>
					{$product.data.name === '' ? (
						<Skeleton variant='rect' height={250} className={classes.skeleton} />
					) : (
						<Carousel />
					)}
				</div>
				<div className={classes.block} style={{ margin: $screen.size ? '40px 0' : '0 0 40px' }}>
					{$product.data.name === '' ? (
						<>
							<Skeleton variant='rect' height={50} className={classes.skeleton} />
							<Skeleton variant='rect' height={100} className={classes.skeleton} />
							<Skeleton variant='rect' height={70} width={70} className={classes.skeleton} />
							<Skeleton variant='text' className={classes.skeleton} />
							<Skeleton variant='rect' height={50} className={classes.skeleton} />
							<Skeleton variant='text' className={classes.skeleton} />
							<Skeleton variant='rect' height={50} className={classes.skeleton} />
							<Skeleton variant='text' className={classes.skeleton} />
							<Skeleton variant='rect' height={50} className={classes.skeleton} />
							<Skeleton variant='rect' height={50} className={classes.skeleton} />
							<Skeleton variant='rect' height={40} width={150} className={classes.skeleton} />
							<Skeleton variant='rect' height={40} width={150} className={classes.skeleton} />
						</>
					) : (
						<>
							<ProductInfo />
							<ColorSelector />
							<Details />
							<Counter />
							<AddToCart />
						</>
					)}
				</div>
			</div>
		</>
	);
};
