import React from 'react';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
	menuBlock: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: '0 20px',
	},
	menuItem: {
		fontFamily: 'Mont-thin',
		fontSize: '40px',
		margin: '0 50px 20px 0',
		cursor: 'pointer',
		transition: '0.2s',
		borderBottom: '1px solid transparent',

		'&:hover': {
			borderBottom: '1px solid #000',
		},
	},
	devider: {
		margin: '10px 0',
	},
	menuCategory: {
		color: '#9c9c9c',
		marginTop: '40px',
	},
});

export const Menu = () => {
	const classes = useStyles();

	const history = useHistory();

	const { $categoryList } = useStore(effector.stores);

	return (
		<nav
			style={{
				zIndex: '10',
				position: 'absolute',
				top: '0',
				width: '100%',
				backgroundColor: '#fff',
			}}>
			<div className={classes.menuCategory} style={{ marginTop: '0' }}>
				Мебель
			</div>
			<Divider className={classes.devider} />
			<div className={classes.menuBlock}>
				{$categoryList.data.map((item) => {
					return (
						<span
							key={item.id}
							className={classes.menuItem}
							onClick={() => history.push(`/products/page=1/category=${item.id}/`)}>
							{item.name}
						</span>
					);
				})}
			</div>
			<div className={classes.menuCategory}>О нас</div>
			<Divider className={classes.devider} />
			<div className={classes.menuBlock}>
				{/* <span className={classes.menuItem} onClick={() => history.push('/credit')}>
					Кредит
				</span> */}
				{/* <span className={classes.menuItem} onClick={() => history.push('/order')}>
					Заказ
				</span> */}
				<span className={classes.menuItem} onClick={() => history.push('/magres')}>
					Magres Baldai
				</span>
				<span className={classes.menuItem} onClick={() => history.push('/ideallux')}>
					Ideal Lux
				</span>
			</div>
			<div className={classes.menuCategory}>Контакты</div>
			<Divider className={classes.devider} />
			<div className={classes.menuBlock}>
				<a href='tel:999274444' rel='noreferrer' target='_blank' className={classes.menuItem}>
					<span >999 27 4444</span>
				</a>
				<a href='https://g.page/baltichomeuz?share' rel='noreferrer' target='_blank' className={classes.menuItem}>
					<span >Локация</span>
				</a>
			</div>
			<div className={classes.menuCategory}>Социальные сети</div>
			<Divider className={classes.devider} />
			<div className={classes.menuBlock}>
				<a href='https://www.instagram.com/baltichomeuz/' rel='noreferrer' target='_blank' className={classes.menuItem}>
					<span>Instagram</span>
				</a>
				<a href='https://t.me/baltichomeuz' rel='noreferrer' target='_blank' className={classes.menuItem}>
					<span>Telegram</span>
				</a>
				<a
					href='https://www.facebook.com/baltichomeuz'
					rel='noreferrer'
					target='_blank'
					className={classes.menuItem}>
					<span>Facebook</span>
				</a>
			</div>
		</nav>
	);
};
