import React from 'react';
import { useStore } from 'effector-react';
import { effector as globalEffector } from '../../../global/effector/index';
import { effector } from '../../effector/index';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
	big: {
		width: '100%',
		margin: '100px 0 50px',
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: '5px solid #f1f1f1',
		padding: '40px',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationThickness: '1px',
			cursor: 'pointer',
		},
	},
	small: {
		width: '100%',
		padding: '50px 10px',
		margin: '50px 0',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: '5px solid #f1f1f1',
	},
});

export const BigPromo = () => {
	const classes = useStyles();

	const history = useHistory();

	const { $screen } = useStore(globalEffector.stores);

	const { $landing } = useStore(effector.stores);

	return (
		<div
			onClick={() => history.push($landing.data.big.link)}
			className={clsx({
				[classes.big]: $screen.size,
				[classes.small]: !$screen.size,
			})}>
			<img
				style={{ maxWidth: '100%', maxHeight: '300px' }}
				src={$landing.data.big.img.url}
				alt={$landing.data.big.img.name}
			/>
			<div
				style={{
					width: '100%',
					fontFamily: 'Mont-thin',
					textAlign: 'center',
					fontSize: $screen.size ? '40px' : '30px',
					marginTop: $screen.size ? '0' : '30px',
				}}>
				{$landing.data.big.title}
			</div>
		</div>
	);
};
