import React from 'react';
import { useStore } from 'effector-react';
import { effector as globalEffector } from '../../../global/effector/index';
import { makeStyles } from '@material-ui/core/styles';
import { effector } from '../../effector/index';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
	block: {
		width: '100%',
		margin: '0 10px',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationThickness: '1px',
			cursor: 'pointer',
		},
	},
	img: {
		maxWidth: '100%',
	},
});

export const HalfPromo = () => {
	const classes = useStyles();

	const history = useHistory();

	const { $screen } = useStore(globalEffector.stores);

	const { $landing } = useStore(effector.stores);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				marginBottom: '50px',
				flexWrap: $screen.size ? 'nowrap' : 'wrap',
			}}>
			<div className={classes.block} onClick={() => history.push($landing.data.half1.link)}>
				<img
					className={classes.img}
					src={$landing.data.half1.img.url}
					alt={$landing.data.half1.img.name}
				/>
				<div
					style={{
						fontFamily: 'Mont-thin',
						margin: '20px',
						fontSize: $screen.size ? '40px' : '30px',
					}}>
					{$landing.data.half1.title}
				</div>
			</div>
			<div className={classes.block} onClick={() => history.push($landing.data.half2.link)}>
				<img
					className={classes.img}
					src={$landing.data.half2.img.url}
					alt={$landing.data.half2.img.name}
				/>
				<div
					style={{
						fontFamily: 'Mont-thin',
						margin: '20px',
						fontSize: $screen.size ? '40px' : '30px',
					}}>
					{$landing.data.half2.title}
				</div>
			</div>
		</div>
	);
};
