import React from 'react';
import { useStore } from 'effector-react';
import { effector } from '../../effector/index';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const Promo = () => {
	const { $screen, $promo } = useStore(effector.stores);

	return (
		<div
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: '#ffffff8c',
				zIndex: 100,
				display: 'flex',
				justifyContent: 'center',
			}}>
			<div
				style={{
					marginTop: '100px',
					height: $screen.size ? '500px' : '300px',
					position: 'relative',
				}}
				onClick={() => effector.events.closePromo()}>
				<div
					style={{
						width: '40px',
						height: '40px',
						position: 'absolute',
						borderRadius: '50%',
						top: '-10px',
						right: '-10px',
						backgroundColor: ' #fff',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
					}}>
					<HighlightOffIcon style={{ fontSize: '45px' }} />
				</div>
				<img
					style={{ height: $screen.size ? '500px' : '300px' }}
					src={$promo.data.url}
					alt={$promo.data.name}
				/>
			</div>
		</div>
	);
};
