import React from 'react';
import { useStore } from 'effector-react';
import { effector as globalEffector } from '../../../global/effector/index';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';

export const CategoryList = () => {
	const { $categoryList } = useStore(globalEffector.stores);

	const history = useHistory();

	const urlParams = useParams();

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexWrap: 'wrap',
				padding: '20px 0 0 20px',
			}}>
			{$categoryList.data.map((item) => {
				return (
					<Button
						style={{ margin: '0 20px 20px 0' }}
						variant={Number(urlParams.category) === item.id ? 'contained' : 'outlined'}
						key={`${item.id}CATEGORYLIST`}
						onClick={() => history.push(`/products/page=1/category=${item.id}/`)}>
						{item.name}
					</Button>
				);
			})}
		</div>
	);
};
