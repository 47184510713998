import React from 'react';
import { effector as globalEffector } from '../../../global/effector/index';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';
import { makeStyles } from '@material-ui/styles/';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
	block: {
		maxWidth: '450px',
		marginBottom: '30px',

		'&:hover': {
			textDecoration: 'underline',
			textDecorationThickness: '1px',
			cursor: 'pointer',
		},
	},
});

export const ItemList = () => {
	const classes = useStyles();

	const histroy = useHistory();

	const { $screen } = useStore(globalEffector.stores);

	const { $productsList } = useStore(effector.stores);

	

	return (
		<div
			style={{
				padding: '20px',
				display: 'flex',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
			}}>
			{$productsList.data.currentList.map((item) => {
				return (
					<div
						key={item.id}
						className={classes.block}
						onClick={() => histroy.push(`/products/id=${item.id}`)}>
						<img style={{ width: '100%' }} src={item.previewImg.url} alt={item.previewImg.name} />
						<div
							style={{
								fontFamily: 'Mont-thin',
								textAlign: 'center',
								fontSize: $screen.size ? '40px' : '30px',
							}}>
							{item.name}
						</div>
					</div>
				);
			})}
		</div>
	);
};
