import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useStore } from 'effector-react';
import { effector } from '../../effector/index';

import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
	row: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '20px',
	},
	total: {
		fontSize: '20px',
		fontFamily: 'Mont-semi',
	},
});

export const CheckCounter = () => {
	const classes = useStyles();

	const { $cart, $check } = useStore(effector.stores);

	useEffect(() => {
		const finalPrice = $cart.data.reduce(
			(sum, cur) => sum + Number(cur.price.cost * cur.price.qty),
			0
		);

		const nonVatPrice = Math.ceil(finalPrice / 1.15);

		const vat = finalPrice - nonVatPrice;

		effector.events.checkInfo({ finalPrice: finalPrice, vat: vat, discount: '' });
	}, [$cart.data]);

	const formatter = new Intl.NumberFormat({
		style: 'currency',
		currency: 'UZS',
		minimumFractionDigits: 0,
	});

	const countProducts = () => {
		return $cart.data.reduce((sum, cur) => sum + Number(cur.price.qty), 0);
	};

	return (
		<div style={{ padding: '0 20px' }}>
			<Divider />
			<div style={{ padding: '20px 20px 0 20px' }}>
				<div className={classes.row}>
					<span>Кол-во товаров:</span>
					<span>{countProducts()} шт</span>
				</div>
				<div className={classes.row}>
					<span>Товары:</span>
					<span>{formatter.format(Math.ceil($check.finalPrice / 1.15))} СУМ</span>
				</div>
				<div className={classes.row}>
					<span>НДС 15%:</span>
					<span>{formatter.format($check.vat)} СУМ</span>
				</div>
				<div className={classes.row}>
					<span className={classes.total}>Итого:</span>
					<span className={classes.total}>{formatter.format($check.finalPrice)} СУМ</span>
				</div>
			</div>
			<Divider />
		</div>
	);
};
