import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';
import { Notification } from '../../../../presets/notification/index';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DateRangeIcon from '@material-ui/icons/DateRange';

import PaymeImg from '../../../../images/payme_logo.svg';

const useStyles = makeStyles({
	input: {
		marginBottom: '20px',
		width: '100%',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '5px',
	},
	icon: {
		marginRight: '10px',
	},
});

export const Payment = () => {
	const classes = useStyles();

	const [modal, setModal] = useState({ render: false, modal: false });

	const [error, setError] = useState(false);

	const [card, setCard] = useState({ card: '', month: '', year: '' });

	const { $payment } = useStore(effector.stores);

	const submitInfo = () => {
		if (card.card !== '' && card.month !== '' && card.year !== '') {
			effector.events.paymentInfo({ card: card.card, exp: `${card.month + card.year}` });
			setModal({ render: true, modal: false });
			setCard({ number: '', month: '', year: '' });
		} else {
			setError(true);
		}
	};

	const cardView = () => {
		if ($payment.card !== '') {
			return `${
				$payment.card[0] + $payment.card[1] + $payment.card[2] + $payment.card[3]
			} **** **** **** **${$payment.card[14] + $payment.card[15]}`;
		}
	};

	return (
		<>
			<div
				style={{
					margin: '20px',
					padding: '20px',
					borderRadius: '10px',
					backgroundColor: '#9696961f',
				}}>
				<div
					style={{
						fontFamily: 'Mont-semi',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: '20px',
						fontSize: '20px',
					}}>
					Оплата
					<Button onClick={() => setModal({ render: true, modal: true })}>
						<EditIcon />
					</Button>
				</div>
				<div>
					<div className={classes.row}>
						{$payment.card === '' ? (
							''
						) : (
							<div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<CreditCardIcon className={classes.icon} /> {cardView()}
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<DateRangeIcon className={classes.icon} />{' '}
									{$payment.exp.split('').splice(0, 2).join('')}/
									{$payment.exp.split('').splice(2, 2).join('')}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<Dialog
				maxWidth='xs'
				open={modal.modal}
				onExited={() => setModal({ render: false, modal: false })}>
				<DialogTitle>Платежные данные</DialogTitle>
				<Divider />
				<DialogContent style={{ padding: '20px 20px 0 20px' }}>
					<DialogContentText>
						Данные вашей карты не передаются магазину BALTIC HOME и сохраняются исключительно на
						безопасной территории сервиса{' '}
						<a href='https://cdn.payme.uz/terms/main.html' rel='noreferrer' target='_blank'>
							Payme
						</a>
						<img src={PaymeImg} alt='payme-logo' style={{ height: '30px', marginTop: '20px' }} />
					</DialogContentText>
					<TextField
						label='Номер карты'
						type='number'
						className={classes.input}
						variant='outlined'
						value={card.card}
						onChange={(e) => {
							if (e.target.value.split('').length <= 16) {
								setCard({ ...card, card: e.target.value });
							}
						}}
					/>
					<div className={classes.input}>
						<TextField
							label='Месяц - 00'
							type='number'
							variant='outlined'
							name='month'
							style={{ width: '120px', marginRight: '20px' }}
							value={card.month}
							onChange={(e) => {
								if (e.target.value.split('').length <= 2) {
									setCard({ ...card, month: e.target.value });
								}
							}}
						/>
						<TextField
							label='Год - 00'
							type='number'
							variant='outlined'
							name='year'
							style={{ width: '120px' }}
							value={card.year}
							onChange={(e) => {
								if (e.target.value.split('').length <= 2) {
									setCard({ ...card, year: e.target.value });
								}
							}}
						/>
					</div>
					<Divider />
					<DialogActions>
						<Button
							color='primary'
							onClick={() => {
								setModal({ render: true, modal: false });
								setCard({ number: '', month: '', year: '' });
							}}>
							Закрыть
						</Button>
						<Button type='submit' color='primary' onClick={submitInfo}>
							Сохранить
						</Button>
					</DialogActions>
				</DialogContent>
				<Notification
					text='Заполните правильно все поля'
					onExited={() => setError(false)}
					type='error'
					state={error}
				/>
			</Dialog>
		</>
	);
};
