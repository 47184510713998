import React from 'react';
import { effector } from '../../effector/index';
import { useStore } from 'effector-react';
import { effector as globalEffector } from '../../../global/effector/index';
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export const ItemList = () => {
	const { $cart } = useStore(effector.stores);

	const { $screen } = useStore(globalEffector.stores);

	const history = useHistory()

	const formatter = new Intl.NumberFormat({
		style: 'currency',
		currency: 'UZS',
		minimumFractionDigits: 0,
	});

	return (
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			{$cart.data.length === 0 ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						height: $screen.size ? '500px' : '200px',
						fontFamily: 'Mont-thin',
						fontSize: '20px',
					}}>
					В корзине ничего нет
				</div>
			) : (
				$cart.data.map((item, index) => {
					return (
						<div
							onClick={() => history.push(`/products/id=${item.id}`)}
							key={`${item.id}${index}`}
							style={{
								borderRadius: '10px',
								border: '1px solid #0000001f',
								margin: '20px',
								padding: '20px',
								width: $screen.size ? '' : '100%',
							}}>
							<div>
								<img
									src={item.previewImg.url}
									alt={item.previewImg.name}
									style={{ width: $screen.size ? '200px' : '100%' }}
								/>
							</div>
							<div>
								{item.name} - {item.price.qty}шт
							</div>
							<div>{formatter.format(item.price.cost)} СУМ</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									marginTop: '10px',
								}}>
								<img
									src={item.price.img.url}
									alt={item.price.img.name}
									style={{ height: '35px', width: '35px', borderRadius: '5px' }}
								/>
								<Button onClick={(e) => {
									e.stopPropagation()
									effector.events.deleteItemInCart(index)
								}} variant='outlined'>
									<DeleteOutlineIcon />
								</Button>
							</div>
						</div>
					);
				})
			)}
		</div>
	);
};
